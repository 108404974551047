import {
  AppAccessEnum,
  ServicesAndRequestsAccess,
  WeaponWarehousesAccessEnum,
  SystemAdminAccessEnum,
} from '@shared/enums/app-access.enum';

export const APP_ROUTES = {
  errorPage: 'error-page',
  login: 'login',
  home: '',
  unauthorized: '401',
  forbidden: '403',
  notFound: '404',
  userData: 'user-data',
  accessControl: {
    root: {
      path: 'UserPermissions',
      permissions: {
        code: AppAccessEnum.ac_AccessManagement,
      },
    },
  },
  taskManagement: {
    root: {
      path: 'Tasks',
    },
    list: {
      path: '',
    },
  },
  securityPermissions: {
    root: {
      path: 'SecurityPermissions',
      permissions: {
        code: 'sp_',
      },
    },
    list: {
      path: 'List',
    },
  },
  securityReports: {
    root: {
      path: 'SecurityReports',
      permissions: {
        code: 'sr_',
      },
    },
  },
  securityNotes: {
    root: {
      path: 'SecurityNotes',
      permissions: {
        code: 'sn_',
      },
    },
    list: {
      path: 'List',
    },
    edit: {
      path: 'Edit',
    },
    details: {
      path: 'Details',
    },
  },
  contractors: {
    root: {
      path: 'contractors',
      permissions: {
        code: AppAccessEnum.ContractorUsers_Contractor_Read,
      },
    },
  },
  scModule: {
    root: {
      path: 'SecurityIncidents',
      permissions: {
        code: [
          ServicesAndRequestsAccess.si_Incident_View,
          ServicesAndRequestsAccess.si_Incident_Post,
          ServicesAndRequestsAccess.si_OperationRoom_Management,
        ],
      },
    },
  },
  shiftsManagement: {
    root: {
      path: 'ShiftsManagement',
      permissions: {
        code: 'sm_',
      },
    },
  },
  news: {
    root: {
      path: 'news',
      permissions: {
        code: AppAccessEnum.SystemSettings_News_Manage,
      },
    },
  },
  statistics: {
    root: {
      path: 'dashboard',
      permissions: {
        code: [
          AppAccessEnum.sts_General_View,
          AppAccessEnum.sts_PermissionRequests_View,
          AppAccessEnum.sts_SecurityReports_View,
          AppAccessEnum.sts_SecurityNotes_View,
        ],
      },
    },
  },
  dropdowns: {
    root: {
      path: 'dropdowns',
      permissions: {
        code: AppAccessEnum.SystemSettings_DropDowns_Manage,
      },
    },
  },
  feedback: {
    root: {
      path: 'feedback',
      permissions: {
        code: AppAccessEnum.SystemSettings_Feedbacks_Manage,
      },
    },
  },
  locationsManagement: {
    root: {
      path: 'locations-management',
      permissions: {
        code: [
          WeaponWarehousesAccessEnum.Read,
          AppAccessEnum.lm_Location_Managment,
        ],
      },
    },
  },
  delegations: {
    root: {
      path: 'delegations',
      permissions: {
        code: AppAccessEnum.DelegationList,
      },
    },
    edit: {
      path: 'edit',
    },
  },
  weapons: {
    root: {
      path: 'Weapons',
      permissions: {
        code: AppAccessEnum.WeaponsModule,
      },
    },
    myTasks: 'my-tasks',
    gateArmingRequest: {
      root: 'GateArmingRequest',
      add: 'add',
      edit: 'edit',
      details: 'Details',
    },
    weaponsFormsManagement: {
      root: 'weapons-forms-management',
      add: 'add',
      edit: 'edit',
    },
    weaponsForms: {
      root: 'weapons-forms',
    },
    menArmingRequests: {
      root: 'SecurityManArmingRequest',
      add: 'add',
      details: 'Details',
      edit: 'edit',
    },
    securityMenManagement: {
      root: 'SecurityMenManagement',
      details: 'Details',
      tasks: 'tasks',
      edit: 'edit',
    },
    settings: {
      root: 'settings',
      courses: 'training-courses-validity',
      notifications: 'notifications',
    },
    safes: {
      root: 'safes',
      add: 'add',
      edit: 'edit',
    },
    safesHandover: {
      root: 'safes-handover',
    },
    statistics: {
      root: 'statistics',
      safesPerLocation: 'safes-per-location',
      weaponsPerGate: 'weapons-per-gate',
    },
  },
  servicesAndRequests: {
    root: 'ServicesAndRequests',
    maintenance: {
      root: {
        path: 'MaintenanceRequest',
        permissions: {
          code: [
            ServicesAndRequestsAccess.ssr_MaintenanceRequest_View,
            ServicesAndRequestsAccess.ssr_MaintenanceRequest_Post,
          ],
        },
      },
      list: {
        path: 'list',
        permissions: {
          code: ServicesAndRequestsAccess.ssr_MaintenanceRequest_View,
        },
      },
      add: {
        path: '',
        permissions: {
          code: ServicesAndRequestsAccess.ssr_MaintenanceRequest_Post,
        },
      },
      details: 'Details',
    },
    maintenanceDepartment: {
      root: {
        path: 'maintenance-department',
        permissions: {
          code: ServicesAndRequestsAccess.ssr_MaintenanceDepartment_Post,
        },
      },
    },
    locations: {
      root: {
        path: 'NewLocation',
        permissions: {
          code: [
            ServicesAndRequestsAccess.ssr_NewLocationRequest_View,
            ServicesAndRequestsAccess.ssr_NewLocationRequest_Post,
          ],
        },
      },
      list: {
        path: 'list',
        permissions: {
          code: ServicesAndRequestsAccess.ssr_NewLocationRequest_View,
        },
      },
      add: {
        path: 'add',
        permissions: {
          code: ServicesAndRequestsAccess.ssr_NewLocationRequest_Post,
        },
      },
      details: 'Details',
    },
  },
  systemAdmin: {
    root: {
      path: 'system-admin',
      permissions: {
        code: SystemAdminAccessEnum.ac_SystemAdminManagement_List,
      },
    },
  },
};
